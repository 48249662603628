/* Establecer el fondo en el body para que no se vea afectado por el contenido */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-image: url('../../public/images/background.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Mantiene el fondo fijo */
}

.reparto-container {
    text-align: center;
    min-height: 100vh;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5); /* Oscurece el fondo sin afectar el contenido */
}

h1 {
    color: white;
    font-size: 2.5em;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.integrantes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 40px;
}

.contenedor {
    width: 60%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contenedor:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.foto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 3px solid #007bff;
}

.info {
    text-align: left;
    color: #333;
}

.info h3 {
    margin: 0;
    font-size: 1.5em;
}

.info ul {
    list-style: none;
    margin: 10px 0 0;
    padding: 0;
}

.info li {
    font-size: 1.1em;
    color: #666;
}

/* Diseño responsivo */
@media (max-width: 768px) {
    .contenedor {
        width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 15px;
    }

    .foto {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .info ul {
        text-align: left;
    }
}
