/* Estilos para la vista de administrador */
.admin-container {
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4;
    background-image: url('../../public/images/background.jpg'); /* Reemplaza con la ruta de tu imagen */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

/* Contenedor de información */
.info-container {
    margin: 20px auto;
    padding: 15px;
    background: rgba(255, 255, 255, 0.95); /* Ligero fondo blanco translúcido */
    border-left: 5px solid #007bff; /* Borde lateral para resaltar */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    text-align: justify;
    font-size: 16px;
    color: #333;
}

.info-container h2 {
    font-size: 20px;
    color: #0056b3;
    margin-bottom: 10px;
    text-align: center;
}

/* Contenedor de cada gráfico */
.chart-container {
    margin: 20px auto;
    padding: 15px;
    background: rgba(255, 255, 255, 0.9); /* Añadido para mejorar la visibilidad sobre el fondo */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Caja que contiene la gráfica */
.chart-box {
    padding: 10px;
    background: #f9f9f9;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
}
