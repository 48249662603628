.documentation-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: black;
  }
  
  .documentation-container h1{
    color: black;
  }
  .section-container {
    background: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h1, h2 {
    color: #333;
    text-align: center;
  }
  
  p {
    color: #555;
  }
  
  .buttons-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .buttons-list li {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
  }
  
  .large-button {
    display: block; /* Hace que el enlace se comporte como un botón */
    text-align: center;
    text-decoration: none; /* Elimina el subrayado de los enlaces */
    width: 100%;
    padding: 15px;
    font-size: 16px;
    background: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s;
}

.large-button:hover {
    background: #0056b3;
}
  .video-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  